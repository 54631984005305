<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">外包档案管理</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >

        <el-breadcrumb-item>外包档案管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">甲方:</div>
          <el-select
            v-model="firstParty"
            placeholder="甲方"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in GovernmentList"
              :key="item.ID"
              :label="item.NameinChinese"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">合同状态:</div>
          <el-select
            v-model="agreementStatus"
            placeholder="合同状态"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">合同有效日期:</div>
          <el-date-picker
            v-model="daterange1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getManpowerSupplierList(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add btn-pre" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
        <button class="btn-pre" @click="dialogVisible2 = true">
          <i class="fa fa-upload"></i>导入
        </button>
        <button class="btn-pre" @click="exportManpowerSupplierListTemplate">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :empty-text="loading ? '加载中...' : '暂无数据'"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="S/N"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="FirstPartyName"
            label="First Party"
            width="140"
            sortable
          />
          <el-table-column
            prop="ContractValidity"
            label="Contract Validity"
            width="155"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.ContractValidity | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column
            prop="AgreementStatus"
            label="Agreement Status"
            width="130"
          >
            <template slot-scope="scope">
              {{ scope.row.AgreementStatus | labelFrom(options) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="MDMSystemSN"
            label="MDM System S/N"
            width="130"
          />
          <el-table-column
            prop="ICMSSystemSN"
            label="ICMS System S/N"
            width="130"
          />
          <el-table-column
            prop="SupplierName"
            label="Supplier Name"
            width="120"
          />
          <el-table-column
            prop="AbbreviationName"
            label="Abbreviation Name"
            width="140"
          />
          <el-table-column prop="BankName" label="Bank Name" width="100" />
          <el-table-column
            prop="AccountName"
            label="Account Name"
            width="120"
          />
          <el-table-column prop="IBANNO" label="IBAN NO." width="80" />
          <el-table-column
            prop="CoordinatorName"
            label="Coordinator Name"
            width="140"
          />
          <el-table-column
            prop="ContactNumber"
            label="Contact Number"
            width="140"
          />
          <el-table-column
            prop="EmailAddress"
            label="Email Address"
            width="130"
          />
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                @click="edit(scope.row)"
                v-if="powers.update"
              ></i>
              <i
                class="fa fa-trash"
                @click="deleteManpowerSupplierListItem(scope.row)"
                v-if="powers.delete"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getManpowerSupplierList(1)"
          @current-change="getManpowerSupplierList"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="当地岗位服务外包单位档案管理"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <Oufm
          @update="getManpowerSupplierList"
          @close="handleClose"
          ref="oufm"
        ></Oufm>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button class="pop-save" @click="save">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="导入当地岗位服务外包单位档案管理"
      :visible.sync="dialogVisible2"
      width="600px"
      :before-close="handleClose2"
      top="50px"
      class="dialog"
    >
      <div class="text-box">
        <div class="text-title">模板文件</div>
        <div class="text-name" @click="getDownloadUrl(downloadUrl)">
          当地岗位服务外包单位档案管理批量导入模板
        </div>
      </div>
      <div class="upload-box2">
        <div class="left">
          <div v-if="name2" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name2 }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment"
          ref="uploadRef"
        >
          <button class="btn-up">
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose2">关闭</el-button>
        <el-button
          class="pop-save pop-right"
          @click="importManpowerSupplierListTemplate"
          >导入</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getCostAllocationItem, uploadAttachment } from "@/api/user";
import {
  getManpowerSupplierList,
  getGovernmentSystemDicAll,
  deleteManpowerSupplierListItem,
  importManpowerSupplierListTemplate,
  getManpowerSupplierListTemplate,
  exportManpowerSupplierListTemplate,
} from "@/api/table";
import Oufm from "@/components/tables/oufm.vue";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    labelFrom(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : "";
    },
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogType: 1, //1新建2、编辑
      disabled: false,
      options: [
        {
          value: 1,
          label: "Implementation",
        },
        {
          value: 2,
          label: "Termination",
        },
        {
          value: 3,
          label: "Black list",
        },
      ],
      GovernmentList: [],
      options2: [
        {
          value: 1,
          label: "Implementation",
        },
        {
          value: 2,
          label: "Termination",
        },
        {
          value: 3,
          label: "Black list",
        },
      ],
      page: 1,
      size: 10,
      total: 10,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      loading: true,
      firstParty: null,
      agreementStatus: null,
      orderByField: null,
      ascOrDesc: null,
      daterange1: null,
      EditState: 1,
      downloadUrl: null,
      name2: null,
    };
  },
  components: { Oufm },
  methods: {
    handleClose() {
      this.$refs.oufm.clearData();
      this.dialogVisible = false;
      this.Id = 0;
      this.ParentId = 0;
      this.DepartmentId = 0;
      this.disabled = false;
      this.EditState = 1;
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.name2 = null;
    },
    newBuilt() {
      this.dialogVisible = true;
      // this.$router.push("/module/oufmTable");
    },
    edit(data) {
      // this.dialogVisible = true;
      // this.EditState = data.EditState;
      // this.getCostAllocationItem(data.KeyID);
      this.$router.push(`/module/oufmTable?id=${data.KeyID}`);
    },
    query(data) {
      this.dialogVisible = true;
      this.getCostAllocationItem(data.KeyID);
      this.disabled = true;
    },
    deleteManpowerSupplierListItem(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteManpowerSupplierListItem({
          key: data.KeyID,
        }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getManpowerSupplierList();
          }
        });
      });
    },

    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }
      const item = this.$store.state.userPower.find((item) => {
        return item.Id === 337;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取列表
    getManpowerSupplierList(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        firstParty: this.firstParty,
        agreementStatus: this.agreementStatus,
        orderByField: this.orderByField,
        ascOrDesc: this.ascOrDesc,
        contractValidityStartDate: this.daterange1 ? this.daterange1[0] : "",
        contractValidityEndDate: this.daterange1 ? this.daterange1[1] : "",
      };
      getManpowerSupplierList(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //保存
    save() {
      this.$refs.oufm.addCostAllocation(1);
    },
    //发布
    release() {
      this.$refs.oufm.addCostAllocation(2);
    },
    //表单详情
    getCostAllocationItem(key) {
      getCostAllocationItem({ key, costAllocation: "CostAllocation5" }).then(
        (res) => {
          if (res.status === 200) {
            this.$refs.costTable.geteditData(
              res.extend.Projects,
              res.extend.Companies,
              res.extend.Departments,
              res.response
            );
          }
        }
      );
    },
    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
          console.log("this.name2", this.name2);
        }
      });
    },
    //导入
    importManpowerSupplierListTemplate() {
      const data = {
        Path: this.uploadUrl,
        KeyID: this.KeyID,
      };
      importManpowerSupplierListTemplate(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose2();
          this.getManpowerSupplierList();
        }
      });
    },

    //导出
    exportManpowerSupplierListTemplate() {
      const data = {
        firstParty: this.firstParty,
        agreementStatus: this.agreementStatus,
        orderByField: this.orderByField,
        ascOrDesc: this.ascOrDesc,
        contractValidityStartDate: this.daterange1 ? this.daterange1[0] : "",
        contractValidityEndDate: this.daterange1 ? this.daterange1[1] : "",
      };
      exportManpowerSupplierListTemplate(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
    //下载附件
    getDownloadUrl(url) {
      location.href = url;
    },
  },

  created() {
    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        this.GovernmentList = res.response;
      }
    });

    getManpowerSupplierListTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl = "https://hrms.baishulai.com/" + res.response;
      }
    });
    this.getManpowerSupplierList();
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 143px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .upload-box {
    display: flex;
    height: 34px;
    margin-top: 24px;
    .left {
      width: 100%;
      border: 1px solid #d2d6de;
      border-right: none;
      .name {
        display: flex;
        align-items: center;
        height: 34px;
        color: #555;
        font-size: 14px;
        text-indent: 2px;
      }
    }
    .btn-up {
      padding: 0;
      margin: 0;
      border: none;
      width: 90px;
      flex-shrink: 0;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #3c8dbc;
      border: 1px solid #367fa9;
      color: #fff;
      font-size: 14px;
      .icon {
        margin-right: 5px;
      }
    }
    .disabled {
      cursor: no-drop;
    }
    .btn-up:hover {
      border-color: #204d74;
      background-color: #367fa9;
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box2 {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  // .el-table__body,
  // .el-table__footer,
  // .el-table__header {
  //   table-layout: auto;
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
  // .el-table__header {
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
