<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-left" colspan="8">
            <h3>Supplier Basic Information 岗位外包单位基本信息</h3>
          </td>
        </tr>
        <tr>
          <td class="text-center">Supplier Name<br />岗位外包单位名称</td>
          <td class="text-center">
            Abbreviation Name<br />
            单位名称缩写
          </td>
          <td class="text-center">
            CR NO.<br />
            营业执照号
          </td>
          <td class="text-center">
            CR Valid Date<br />
            营业执照有效期
          </td>
          <td class="text-center">
            VAT NO.<br />
            增值税号
          </td>
          <td class="text-center" colspan="2">
            P.O. Box & Office Address<br />
            公司注册地址
          </td>
          <td class="text-center">
            FAT Ability<br />
            Besides East Province
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-input v-model="SupplierName" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="AbbreviationName" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="CRNO" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="CRValidDate"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-input v-model="VATNO" placeholder="请输入 Enter" />
          </td>
          <td class="text-center" colspan="2">
            <el-input
              v-model="POBoxAndOfficeAddress"
              placeholder="请输入 Enter"
            />
          </td>
          <td class="text-center">
            <el-input v-model="FATAbility" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="8">
            <h3>Beneficiary Bank Information 收款账户信息</h3>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Bank Name<br />
            收款银行名称
          </td>
          <td class="text-center" colspan="2">
            Account Name<br />
            账户名称
          </td>
          <td class="text-center" colspan="2">IBAN NO.</td>
          <td class="text-center">
            Bank Code <br />
            银行代码
          </td>
          <td class="text-center" colspan="2">
            Bank Address<br />
            银行注册地址
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-input v-model="BankName" placeholder="请输入 Enter" />
          </td>
          <td class="text-center" colspan="2">
            <el-input v-model="AccountName" placeholder="请输入 Enter" />
          </td>
          <td class="text-center" colspan="2">
            <el-input v-model="IBANNO" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="BankCode" placeholder="请输入 Enter" />
          </td>
          <td class="text-center" colspan="2">
            <el-input v-model="BankAddress" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="8">
            <h3>Service Agreement Information 服务合同信息</h3>
          </td>
        </tr>
        <tr>
          <td class="text-center">Service Agreement S/N <br />合同编号</td>
          <td class="text-center">
            Registration Date<br />
            首次注册时间
          </td>
          <td class="text-center">
            Contract Validity<br />
            合同有效期
          </td>
          <td class="text-center">
            Agreement Status<br />
            合同执行状态
          </td>
          <td class="text-center">
            MDM System S/N<br />
            MDM系统编号
          </td>
          <td class="text-center">
            ICMS System S/N<br />
            ICMS系统上线编号
          </td>
          <td class="text-center">
            Agreement Value<br />
            合同额
          </td>
          <td class="text-center">
            First Party<br />
            甲方
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-input v-model="ServiceAgreementSN" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="RegistrationDate"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="ContractValidity"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-select
              v-model="AgreementStatus"
              placeholder="请选择 Please select"
              clearable
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-input v-model="MDMSystemSN" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="ICMSSystemSN" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="AgreementValue" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-select
              v-model="FirstParty"
              placeholder="请选择 Please select"
              filterable
              clearable
            >
              <el-option
                v-for="item in GovernmentList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="8">
            <h3>Other Information 其他信息</h3>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Name<br />
            联络人姓名
          </td>
          <td class="text-center">
            Contact Number<br />
            联络人联系电话
          </td>
          <td class="text-center">
            Email Address<br />
            联络人邮箱
          </td>
          <td class="text-center">
            Recommend by<br />
            首次引入项目
          </td>
          <td class="text-center" rowspan="2">
            Remark<br />
            备注
          </td>
          <td colspan="3" rowspan="2">
            <el-input v-model="Remark" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-input v-model="CoordinatorName" placeholder="输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="ContactNumber" placeholder="输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="EmailAddress" placeholder="输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="Recommendby" placeholder="输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-left" colspan="7">
            * All uploaded documents have been uploaded in PDF format, which is
            including all registration files, service agreement and
            supplementary agreement. <br />
            *
            上传材料均以PDF格式上传，包括注册资料、合同扫件、联络人授权信息、银行账户信息等。
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="3">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { uploadAttachment } from "@/api/user";
import {
  addManpowerSupplierListItem,
  editFlightTicketRescheduleApplicationItem,
  getGovernmentSystemDicAll,
  getPurchaseOrderApplicationSuppliers,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    SupplierKeyFormater(id, data) {
      const item = data.find((item) => {
        return item.KeyID == id;
      });
      return item ? item.AbbreviationName : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
  },
  data() {
    return {
      GovernmentList: [],
      purchaseOrderApplicationList: [],
      options1: [
        {
          value: 1,
          label: "正常履行",
        },
        {
          value: 2,
          label: "已关闭",
        },
        {
          value: 3,
          label: "列入黑名单",
        },
      ],
      ID: null,
      KeyID: null,
      ServiceAgreementSN: null,
      FirstParty: null,
      FirstPartyName: null,
      RegistrationDate: null,
      ContractValidity: null,
      AgreementStatus: null,
      MDMSystemSN: null,
      ICMSSystemSN: null,
      AgreementValue: null,
      SupplierName: null,
      AbbreviationName: null,
      CRNO: null,
      CRValidDate: null,
      VATNO: null,
      POBoxAndOfficeAddress: null,
      FATAbility: null,
      BankName: null,
      AccountName: null,
      IBANNO: null,
      BankCode: null,
      BankAddress: null,
      CoordinatorName: null,
      ContactNumber: null,
      EmailAddress: null,
      Recommendby: null,
      Remark: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
    };
  },

  methods: {
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ServiceAgreementSN: this.ServiceAgreementSN,
        FirstParty: this.FirstParty,
        FirstPartyName: this.FirstPartyName,
        RegistrationDate: this.RegistrationDate,
        ContractValidity: this.ContractValidity,
        AgreementStatus: this.AgreementStatus,
        MDMSystemSN: this.MDMSystemSN,
        ICMSSystemSN: this.ICMSSystemSN,
        AgreementValue: this.AgreementValue,
        SupplierName: this.SupplierName,
        AbbreviationName: this.AbbreviationName,
        CRNO: this.CRNO,
        CRValidDate: this.CRValidDate,
        VATNO: this.VATNO,
        POBoxAndOfficeAddress: this.POBoxAndOfficeAddress,
        FATAbility: this.FATAbility,
        BankName: this.BankName,
        AccountName: this.AccountName,
        IBANNO: this.IBANNO,
        BankCode: this.BankCode,
        BankAddress: this.BankAddress,
        CoordinatorName: this.CoordinatorName,
        ContactNumber: this.ContactNumber,
        EmailAddress: this.EmailAddress,
        Recommendby: this.Recommendby,
        Remark: this.Remark,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState,
      };

      if (!this.ID) {
        addManpowerSupplierListItem(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editFlightTicketRescheduleApplicationItem(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
          null;
        });
      }
    },
    getEditData(params) {
      console.log("params", params);
      this.TableDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.DepartmentID = params.DepartmentID;
      this.JobProfession = params.JobProfession;
      this.EmployeeCode = params.EmployeeCode;
      this.EmployeeName = params.EmployeeName;
      this.EmployeeNameEN = params.EmployeeNameEN;
      this.Nationality = params.Nationality;
      this.PassportCode = params.PassportCode;
      this.IQAMAID = params.IQAMAID;
      this.VisaType = params.VisaType;
      this.VisaExpiryDate = params.VisaExpiryDate;
      this.Sponsor = params.SPONSOR;
      this.ReasonofReschedule = params.ReasonofReschedule;
      this.RescheduleStatus = params.RescheduleStatus;
      this.TicketReferenceofPreviousTicket =
        params.TicketReferenceofPreviousTicket;
      this.OneWayorRoundTripofPreviousTicket =
        params.OneWayorRoundTripofPreviousTicket;
      this.AirTicketRouteofPreviousTicket =
        params.AirTicketRouteofPreviousTicket;
      this.AirTicketRouteNameofPreviousTicket =
        params.AirTicketRouteNameofPreviousTicket;
      this.DepartureDatefromKSAofPreviousTicket =
        params.DepartureDatefromKSAofPreviousTicket;
      this.AarrivalDateatKSAofPreviousTicket =
        params.AarrivalDateatKSAofPreviousTicket;
      this.DaysofDurationofPreviousTicket =
        params.DaysofDurationofPreviousTicket;
      this.RemarkofPreviousTicket = params.RemarkofPreviousTicket;
      this.RescheduleStatusofRescheduleTicket =
        params.RescheduleStatusofRescheduleTicket;
      this.TicketReferenceofRescheduleTicket =
        params.TicketReferenceofRescheduleTicket;
      this.OneWayorRoundTripofRescheduleTicket =
        params.OneWayorRoundTripofRescheduleTicket;
      this.AirTicketRouteofRescheduleTicket =
        params.AirTicketRouteofRescheduleTicket;
      this.AirTicketRouteNameofRescheduleTicket =
        params.AirTicketRouteNameofRescheduleTicket;
      this.DepartureDatefromKSAofRescheduleTicket =
        params.DepartureDatefromKSAofRescheduleTicket;
      this.AarrivalDateatKSAofRescheduleTicket =
        params.AarrivalDateatKSAofRescheduleTicket;
      this.DaysofDurationofRescheduleTicket =
        params.DaysofDurationofRescheduleTicket;
      this.RemarkofRescheduleTicket = params.RemarkofRescheduleTicket;
      this.HROfficerMemo = params.HROfficerMemo;
      this.ForeignAffairOfficerMemo = params.ForeignAffairOfficerMemo;
      this.TicketOfficerMemo = params.TicketOfficerMemo;
      this.ChangeForeignRelationsofApplicantto =
        params.ChangeForeignRelationsofApplicantto;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.getProjectsChildren();
      this.WorkingUnitAdminDeptRemark = params.WorkingUnitAdminDeptRemark;
      this.WorkingUnitManagerRemark = params.WorkingUnitManagerRemark;
      this.ProjectAdminDeptRemark = params.ProjectAdminDeptRemark;
      this.ProjectManagerRemark = params.ProjectManagerRemark;
      this.HRDeptRemark = params.HRDeptRemark;
      this.SNEMECAuthorizedRemark = params.SNEMECAuthorizedRemark;
      this.HROfficerRemark = params.HROfficerRemark;
      this.ForeignAffairOfficerRemark = params.ForeignAffairOfficerRemark;
      this.TicketOfficerRemark = params.TicketOfficerRemark;
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ServiceAgreementSN = null;
      this.FirstParty = null;
      this.FirstPartyName = null;
      this.RegistrationDate = null;
      this.ContractValidity = null;
      this.AgreementStatus = null;
      this.MDMSystemSN = null;
      this.ICMSSystemSN = null;
      this.AgreementValue = null;
      this.SupplierName = null;
      this.AbbreviationName = null;
      this.CRNO = null;
      this.CRValidDate = null;
      this.VATNO = null;
      this.POBoxAndOfficeAddress = null;
      this.FATAbility = null;
      this.BankName = null;
      this.AccountName = null;
      this.IBANNO = null;
      this.BankCode = null;
      this.BankAddress = null;
      this.CoordinatorName = null;
      this.ContactNumber = null;
      this.EmailAddress = null;
      this.Recommendby = null;
      this.Remark = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        console.log("res", res);
        this.GovernmentList = res.response;
      }
    });
    getPurchaseOrderApplicationSuppliers().then((res) => {
      if (res.status === 200) {
        this.purchaseOrderApplicationList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>